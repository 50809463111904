import React from 'react'
import { Col } from 'react-bootstrap'
import aboutSec1 from "../../images/about-sec1.png";
import boardingPass from "../../images/boarding-pass-100.png";
import hotel from "../../images/hotel-star-100.png";
import consultation from "../../images/consultation-100.png";
import hajj from "../../images/hajj-100.png";
import trust from "../../images/trust.jpg";


const Updates = () => {
  return (
    <>
      <section className='herobg' style={{ height: '77px', padding:"0px" }}>
      </section>

      <section>
      <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto mt-5 col-11'}>
        coming soon....
        </Col>
        {/* <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto mt-5 col-11'}>
          <div className="row mb-5 howWeWork">
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>About Us</p>
              <h6 className="title">Your Journey, <span>Our Expertise</span></h6>
              <p className='pt-2 mb-3'>
                Welcome to Dhillu and Sahi Travels, a name synonymous with excellence in the world of travel. With a legacy dating back to 2000, we have been fulfilling the travel dreams of countless individuals and families, one destination at a time.
              </p>
              <p className='mb-3'>
                At Dhillu and Sahi Travels, we believe that travel is not just about going from one place to another; it's about creating memories that last a lifetime. We understand the thrill of exploring new horizons, the joy of reuniting with loved ones, and the spiritual significance of a sacred pilgrimage. It's these moments that inspire us to do what we do.
              </p>
              <p className='mb-3'>
                At Dhillu and Sahi Travels, we believe that travel is not just about going from one place to another; it's about creating memories that last a lifetime. We understand the thrill of exploring new horizons, the joy of reuniting with loved ones, and the spiritual significance of a sacred pilgrimage. It's these moments that inspire us to do what we do.
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 ps-lg-5 mt-4 mt-lg-0">
              <div className="row about-sec1">
                <img src={aboutSec1} alt='' />
              </div>
            </div>
          </div>
          <section className="py-5 howWeWork mb-5" style={{ backgroundColor: '#F4F6FC' }}>
            <div className="col-xl-11 col-lg-11 col-11 mx-auto">
              <div className="row">
                <div className="col-xl-6 col-lg-6 ps-lg-5 mt-4 mt-lg-0">
                  <div className="row">
                    <div className="col-sm-6 mb-4 px-3 icon">
                      <img className="img-fluid mb-2" src={boardingPass} alt="icon" />
                      <h6 className="titleInner">Air Ticketing</h6>
                      <p style={{ color: 'rgba(0,0,0,0.7)' }}>Expert Team, Hassle-Free Service. We ensure the lowest airfares on your preferred days and timings.</p>
                    </div>
                    <div className="col-sm-6 mb-4 px-3  icon">
                      <img className="img-fluid mb-2" src={consultation} alt="icon" />
                      <h6 className="titleInner">Visa Consultancy</h6>
                      <p style={{ color: 'rgba(0,0,0,0.7)' }}>Free Visa Consultancy, Swift Processing. Enjoy peace of mind and focus on your trip, making it the best of your lifetime.</p>
                    </div>
                    <div className="col-sm-6 mb-4 px-3 icon">
                      <img className="img-fluid mb-2" src={hajj} alt="icon" />
                      <h6 className="titleInner">Hajj & Umrah</h6>
                      <p style={{ color: 'rgba(0,0,0,0.7)' }}>Spiritual Journeys, Seamless Experience. Expert Hajj and Umrah services tailored to your needs, ensuring a smooth and enriching pilgrimage.</p>
                    </div>
                    <div className="col-sm-6 mb-4 px-3 icon">
                      <img className="img-fluid mb-2" src={hotel} alt="icon" />
                      <h6 className="titleInner">Hotel Booking</h6>
                      <p style={{ color: 'rgba(0,0,0,0.7)' }}>Comfortable Stays, Best Prices. Wide range of accommodations to suit your needs. Pre-arrange your hotel hassle-free.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
                  <p className="m-0 mb-2 mainorange" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Featured</p>
                  <h6 className="title">Our Features & Services</h6>
                  <p className='pt-2 mb-3'>
                    Discover the Dhillu and Sahi Travels Advantage through our exceptional features and services. With a commitment to excellence, we offer a wide array of travel solutions tailored to your needs. Our dedicated team of experts ensures hassle-free ticketing services, providing you with the lowest airfares on your preferred days and timings. Experience the ease of our visa consultancy services, provided at no cost and with swift processing, allowing you to focus on your trip with peace of mind. Choose from our diverse range of accommodations, meticulously curated for comfort and relaxation, all at the best prices. Additionally, our specialized Hajj and Umrah services guarantee a seamless and enriching spiritual journey. At Dhillu and Sahi Travels, we prioritize your satisfaction and convenience, making every travel experience memorable and stress-free.                  </p>
                </div>

              </div>
            </div>
          </section>
          <div className="row mb-5 howWeWork">
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Trust</p>
              <h6 className="title">Why Choose Us?</h6>
              <p className='pt-2 mb-3'>
                <b>Experience: </b>Our team brings a wealth of experience and industry knowledge to every travel plan we create. With us, you benefit from the wisdom gained over the years.
              </p>
              <p className='mb-3'>
                <b>Global Network: </b>Through our partnerships with multiple airlines and a network of trusted partners worldwide, we offer you access to a world of destinations. Wherever you want to go, we can take you there.
              </p>
              <p className='mb-3'>
                <b>Customer-Centric Approach: </b>Your satisfaction is our ultimate goal. We understand that each traveler is unique, and we tailor our services to match your specific needs and preferences.
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 ps-lg-5 mt-4 mt-lg-0">
              <div className="row text-center">
                <div className='about-sec2'>
                  <img src={trust} alt='' />
                </div>
              </div>
            </div>
          </div>
          <section className="py-5 howWeWork mb-5" style={{ backgroundColor: '#F4F6FC' }}>
            <div className="col-xl-11 col-lg-11 col-11 mx-auto">
              <div className="row">
                <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
                  <p className="m-0 mb-2 mainorange" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Our Mission</p>
                  <h6 className="title">The Heart of Our Mission</h6>
                  <p className='pt-2 mb-3'>
                    At the heart of our mission is the belief that travel should be accessible, memorable, and worry-free. Whether you're embarking on a family vacation, a business trip, a spiritual pilgrimage, or any other journey, we're here to make it extraordinary.
                  </p>
                  <p className="m-0 mb-2 mainorange" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Our Journey</p>
                  <h6 className="title">Our Journey, <span>Your Confidence</span></h6>
                  <p className='pt-2 mb-3'>
                    Our journey began over two decades ago in Daska, Pakistan, with a commitment to provide top-notch travel services. Since then, we've grown to become the leading travel agency in Pakistan, serving clients with dedication, integrity, and unwavering passion.
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 ps-lg-5 mt-4 mt-lg-0">
                  <div className="row">
                    <img src={''} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Col> */}
      </section >
    </>
  )
}

export default Updates
