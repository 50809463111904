import React, { useEffect, useState } from 'react';

// import component
import arrowicon from '../../../images/arrowicon.svg';


// import images
import logo1 from '../../../images/airbluelogo.png';
import logo2 from '../../../images/egallogo.png';
import logo3 from '../../../images/emrateslogo.png';
import searchicon from '../../../images/searchicon.svg';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Card, Form, InputGroup, Spinner, Table } from 'react-bootstrap';
import BookingsTRLoop from '../booking-compo/BookingsTRLoop';
import BookingCardLoop from './BookingCardLoop';
import axios from 'axios';
import { API_BASE_URL, formatDate, getBearerToken } from '../../../functions';
import TicketCard from '../../PublicPages/componnents/TicketCard';
import { useAuth } from '../auth/AuthProvider';


const MySectorDetailPage = () => {
    const [mySectorData, setMySectorData] = useState('')
    const [loading, setLoading] = useState(false)
    const mysec = useParams()
    const { token } = useAuth();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    useEffect(() => {
        setLoading(true)
        axios.post(`${API_BASE_URL}/${token.role === 'admin' ? 'pnr-respect-to-mysector' : 'pnr-respect-to-mysector-user'}`, {
            my_sector_id: mysec.id
        }, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                // Handle the response data

                setMySectorData(response?.data?.data)
                setLoading(false)
            }).catch(error => {
                // Handle errors
                console.error(error);
            });
    }, [])

    console.log(mySectorData);
    return (
        <>
            <div className={'main_heading mb-4 d-flex align-items-center justify-content-between'}>
                <div>
                    <h4>Booking List : <span className={'paragraphtext'}>{mysec.name}</span></h4>
                    <Breadcrumb className={'BreadCrumbLinkks m-0 p-0'}>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard' }}>Dashboard</Breadcrumb.Item>
                        {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/bookings/${mysec.id}` }}>Bookings</Breadcrumb.Item> */}
                        <Breadcrumb.Item active>{mysec.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/* <div className="d-flex">
                    <Form onSubmit={handleSearch} className='me-2'>
                        <InputGroup className="mb-0 d-md-flex d-none border rounded">
                            <Form.Control className='border-0'
                                placeholder="Search by PNR"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={(e) => setSetPnr(e.target.value)} value={setPnr} />
                            <Button variant={''} type='submit' id="basic-addon1">
                                <img className={'img-fluid'} src={searchicon} alt={'icon'} />
                            </Button>
                        </InputGroup>
                    </Form>
                    <Form className='me-2'>
                        <InputGroup className="mb-0 d-md-flex d-none border rounded">
                            <Form.Control className='border-0' type='date'
                                aria-label="Username" aria-describedby="basic-addon1"
                                onChange={(e) => setSearchQuesry({ ...searchQuery, date: e.target.value })}
                                value={searchQuery.date} />
                        </InputGroup>
                    </Form>
                    {(searchQuery.date || searchQuery.pnr) ?
                        <Button className='redtext' variant={''}
                            type='button' id="basic-addon1"
                            onClick={() => { setSearchQuesry({ date: '', pnr: null }), setSetPnr(''), setCurrentPage(1) }}
                        >
                            Clear
                        </Button> : null}
                </div> */}
            </div>
            <section className={'bookingPage'}>

                {mySectorData?.length > 0 ?
                    <>
                        {token.role === 'admin' ?
                            <BookingCardLoop props={mySectorData} />
                            :
                            <>
                                <TicketCard data={mySectorData} props={mySectorData} />

                            </>
                        }
                    </>
                    : loading ?
                        (
                            <div className={'mt-3 w-100 text-center'}>
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            </div>
                        ) :
                        <div className="py-3"><p>No Data Found</p></div>}
            </section>
        </>
    )
}

export default MySectorDetailPage;