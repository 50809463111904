import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Button, Dropdown, InputGroup, Form, Offcanvas, NavItem, Col, Row, Nav } from 'react-bootstrap';

// import images
import footer_logo_white from '../../../images/footer_logo_white.svg';
import footer_logo from '../../../images/footer_logo.svg';
import bars from '../../../images/bar.svg';

const Header = (props) => {
    // Notification Offcanvas
    const [showNotification, setShowNotification] = useState(false);
    const handleShowNotification = () => setShowNotification(true);
    const handleCloseNotification = () => setShowNotification(false);
    const navigate = useNavigate()
    const [isLogin, setIsLogin] = useState(false)
    const [scrolling, setScrolling] = useState(false);



    // Attach scroll event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Remove the scroll event listener when component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // CSS class for the header based on scrolling state


    useEffect(() => {
        let checkLogin = localStorage.getItem('token');
        if (checkLogin) {
            setIsLogin(true)
        }
    }, [isLogin]);

    const logout = (e) => {
        e.preventDefault()
        localStorage.removeItem('token');
        setIsLogin(false)
    }
    // Function to handle scroll events
    const handleScroll = () => {
        if (window.scrollY > 0) {
            // If scrolled down, set scrolling to true
            setScrolling(true);
        } else {
            // If at the top, set scrolling to false
            setScrolling(false);
        }
    };

    return (
        <>
            <header className={'webheader'}>
                {/* desktop header */}
                <Navbar expand="lg" className={`d-none d-lg-flex navactive align-items-center ${scrolling ? 'scrollheader' : null}`} style={{ height: '77px' }}>
                    <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto col-11'}>
                        <Row className={'align-items-center'}>
                            <Col xxl={'3'} xl={'3'} lg={'3'}>
                                <div className={'d-flex align-items-center'}>
                                    <Link to={`/`}><img className={'img-fluid fluid'} src={scrolling ? footer_logo : footer_logo_white} alt={'icon'} /></Link>
                                </div>
                            </Col>
                            {/*  */}
                            <Col xxl={'9'} xl={'9'} lg={'9'} className={'text-start'}>
                                <Nav className={'d-flex align-items-center justify-content-end'}>
                                    <NavItem><Link className={'nav-link'} to={`/`}>Home</Link></NavItem>
                                    <NavItem><Link className={'nav-link'} to={`/aboutus`}>About Us</Link></NavItem>
                                    <NavItem><Link className={'nav-link'} to={`/updates`}>Updates</Link></NavItem>
                                    <NavItem><Link className={'nav-link'} to={`/contactus`}>Contact Us</Link></NavItem>
                                    {/* <NavItem><Link className={'nav-link'} to={`/wba`}>WBA</Link></NavItem> */}
                                    <NavItem><Link className={'nav-link'} to={`/bankaccounts`}>Bank Details</Link></NavItem>
                                    {isLogin ?
                                        <>
                                            <NavItem><Link className={'nav-link'} to={`#`} onClick={(e) => logout(e)}>Log out</Link></NavItem>
                                            <NavItem><Button variant={''} onClick={() => JSON?.parse(localStorage.getItem('token'))?.role === 'admin' ? navigate('/dashboard') : navigate('/dashboard')} className={'basic-btn'}>Dashboard</Button></NavItem>
                                        </>
                                        :
                                        <>
                                            <NavItem><Link className={'nav-link'} to={`${process.env.PUBLIC_URL}/signin`}>Log In</Link></NavItem>
                                            <NavItem><Button variant={''} onClick={() => navigate('/signup')} className={'basic-btn'}>Register</Button></NavItem>
                                        </>
                                    }
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Navbar>

                {/* mobile header */}
                <Navbar bg="light" expand="lg" className={'d-block d-lg-none mobileHeader'}>
                    <Col md={'10'} sm={'11'} className={'mx-auto col-11'}>
                        <Row className={'align-items-center'}>
                            <Col md={'9'} sm={'9'} className={'col-9'}>
                                <div className={'d-flex align-items-center'}>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className={''}><img className={'img-fluid w-75'} width={'100'} src={footer_logo} alt={'icon'} /></Link>
                                </div>
                            </Col>
                            <Col md={'3'} sm={'3'} className={'col-3 text-center'}>
                                <div className={'d-flex align-items-center ms-auto justify-content-center w-100 h-100 borderradius100px whitebg'} style={{ maxWidth: '45px', minWidth: '45px', minHeight: '45px', maxHeight: '45px', }}>
                                    <Link to={'#'} onClick={handleShowNotification} className={'me-0'}><img className={'img-fluid'} src={bars} alt={'icon'} /></Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Navbar>
            </header>
            {/* Notification Offcanvas */}
            <Offcanvas className="headeroffcanvas" show={showNotification} placement='end' onHide={handleCloseNotification} {...props}>
                <Offcanvas.Header closeButton className={'m-0'}>
                    <Offcanvas.Title className="w-100">Main Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="">
                    <div className={'mb-4'}>
                        <NavItem><Link className={'nav-link fontsize20 fontweight600 mb-1'} to={`/`}>Home</Link></NavItem>
                        <NavItem><Link className={'nav-link fontsize20 fontweight600 mb-1'} to={`/aboutus`}>About Us</Link></NavItem>
                        <NavItem><Link className={'nav-link fontsize20 fontweight600 mb-1'} to={`/updates`}>Updates</Link></NavItem>
                        <NavItem><Link className={'nav-link fontsize20 fontweight600 mb-1'} to={`/contactus`}>Contact Us</Link></NavItem>
                        {/* <NavItem><Link className={'nav-link fontsize20 fontweight600 mb-1'} to={`/wba`}>WBA</Link></NavItem> */}
                        <NavItem><Link className={'nav-link fontsize20 fontweight600 mb-1'} to={`/bankaccounts`}>Bank Details</Link></NavItem>
                    </div>
                    <header className={'d-flex webheader position-relative header3 align-items-center justify-content-between'} style={{ backgroundColor: 'transparent' }}>
                        {isLogin ?
                            <>
                                <NavItem><Link className={'nav-link'} to={`#`} onClick={(e) => logout(e)}>Log out</Link></NavItem>
                                <NavItem><Button variant={''} onClick={() => JSON?.parse(localStorage.getItem('token'))?.role === 'admin' ? navigate('/dashboard') : navigate('/dashboard')} className={'basic-btn'}>Dashboard</Button></NavItem>
                            </>
                            :
                            <>
                                <NavItem><Link className={'nav-link'} to={`${process.env.PUBLIC_URL}/signin`}>Log In</Link></NavItem>
                                <NavItem><Button variant={''} onClick={() => navigate('/signup')} className={'basic-btn'}>Register</Button></NavItem>
                            </>
                        }
                    </header>
                </Offcanvas.Body>
                {/*  */}
            </Offcanvas>
        </>
    )
}

export default Header;