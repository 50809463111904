import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_BASE_URL, getBearerToken } from '../../functions'
import { useState } from 'react'
import TicketCard from './componnents/TicketCard'
import { Button, Col, Spinner, Form, Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const Tickets = () => {
    const { dip, arriv, date } = useParams()
    const [data, setData] = useState('')
    const [searchQuery, setSearchQuesry] = useState({
        dip: '',
        arriv: '',
        date: ''
    })

    useEffect(() => {
        setSearchQuesry({ dip: dip, arriv: arriv, date: date })
        axios.post(`${API_BASE_URL}/search-flights`, {
            departure: dip,
            arrival: arriv,
            date: date
        }
            , {
                headers: {
                    'Authorization': `Bearer ${getBearerToken()}`
                }
            }
        )
            .then(response => {
                // Handle the response data
                setData(response?.data?.data)
                // console.log('response', response);
                // setLoading(false)
            }).catch(error => {
                // Handle errors
                console.error(error?.response?.data.message);
                // console.log('error', error);

                // setLoading(false)
            });
        window.scrollTo(0, 0);
    }, [])



    const handalSearch = (e) => {
        e.preventDefault()
        setData('')
        axios.post(`${API_BASE_URL}/search-flights`, {
            departure: searchQuery.dip,
            arrival: searchQuery.arriv,
            date: searchQuery.date
        }
            , {
                headers: {
                    'Authorization': `Bearer ${getBearerToken()}`
                }
            }
        )
            .then(response => {
                // Handle the response data
                setData(response?.data?.data)
                // console.log('response', response);
                // setLoading(false)
            }).catch(error => {
                // Handle errors
                console.error(error?.response?.data.message);
                // console.log('error', error);

                // setLoading(false)
            });
    }
    return (
        <>
            <section className='ticketbanner' style={{ paddingTop: '77px' }}>
                <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto col-11 pt-5 pb-3'}>
                    {/* <div className={'main_heading d-flex align-items-center'}>
                        <h4 className='mainorange m-0 p-0'>Search result for : <span className='whitetext'>{dip}-{arriv}, {date}</span></h4>
                    </div> */}
                    <div className='whitebox'>
                        <h5 className='text-uppercase fontweight700 fontsize20'>Where you going ?</h5>
                        <Form className={'AllForm mt-2 d-blok d-md-flex align-items-center w-100 text-center'} onSubmit={handalSearch}>
                            <div className="inputsbox d-flex flex-column flex-md-row">
                                <Form.Group className="form-group w-100" controlId="exampleForm.ControlInput1" style={{ flex: '1 0 0' }}>
                                    <Form.Label>Departure</Form.Label>
                                    <Form.Control

                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'Singapore (SIN)'}
                                        value={searchQuery.dip}
                                        onChange={(e) => setSearchQuesry({ ...searchQuery, dip: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group w-100" controlId="exampleForm.ControlInput1" style={{ flex: '1 0 0' }}>
                                    <Form.Label>Arrival</Form.Label>
                                    <Form.Control

                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        value={searchQuery.arriv}
                                        placeholder={'Dubai (DXB)'}
                                        onChange={(e) => setSearchQuesry({ ...searchQuery, arriv: e.target.value })}

                                    />
                                </Form.Group>
                                <Form.Group className="form-group w-100" controlId="exampleForm.ControlInput1" style={{ flex: '1 0 0' }}>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control

                                        required
                                        type="date"
                                        name={''}
                                        id={''}
                                        value={searchQuery.date}
                                        placeholder={'data'}
                                        onChange={(e) => setSearchQuesry({ ...searchQuery, date: e.target.value })}
                                    />
                                </Form.Group>
                            </div>
                            <Button variant={''} type='submit' className={'basic-btn text-uppercase h-56'}>Search Flight</Button>
                        </Form>
                    </div>
                </Col>
            </section>
            <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto col-11 py-5'}>
                {!data ? (
                    <div className={'mt-3 w-100 text-center'}>
                        {/* <Spinner as='span' animation='border' size='lg' role='status' aria-hidden='true' /> */}
                        <Skeleton count={5} width={'100%'} height={108} style={{ marginBottom: '15px' }} />
                    </div>
                ) :
                    <Table responsive="md" className='booking-table'>
                        <thead>
                            <tr>
                                <th>Airline</th>
                                <th>Sector/Date</th>
                                <th className='text-center'>Timing</th>
                                <th className='text-end'>Baggage/Meal</th>
                                <th className='text-end'>Price</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody >
                            <TicketCard props={data} />
                        </tbody>
                    </Table>
                }
            </Col>
        </>
    )
}

export default Tickets
