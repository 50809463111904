import React, { useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import planeicon from '../../../images/planeicon.svg'
import plane from "../../../images/plane100.png";

import BookTicket from '../../Modals/BookTicket';
import SigninPopup from '../../Modals/SigninPopup';
import { formatDate } from '../../../functions';
import { Link } from 'react-router-dom';


const TicketCard = ({ data, props }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedPnr, setSelectedPnr] = useState({ date: '', pnr: '', price: 0 });
    const [isLogin, setIsLogin] = useState(false);
    const [showSelectedSubparts, setShowSelectedSubparts] = useState(false);
    const [selectedSubpartIndex, setSelectedSubpartIndex] = useState(null);

    useEffect(() => {
        let checkLogin = localStorage.getItem('token');
        if (checkLogin) {
            setIsLogin(true);
        }
    }, []);

    const handleShow = () => {
        let checkLogin = localStorage.getItem('token');
        if (checkLogin) {
            setIsLogin(true);
            setModalShow(true);
        } else {
            setIsLogin(false);
            setModalShow(true);
        }
    };

    const handleLoginSuccess = () => {
        // After a successful login, set isLogin to true and open the BookTicket popup.
        setIsLogin(true);
        setModalShow(true);
    };

    const ShowSubparts = (index) => {
        if (isLogin) {
            setShowSelectedSubparts((prev) => (index === selectedSubpartIndex ? !prev : true));
            setSelectedSubpartIndex(index);
        } else {
            handleShow()
        }
    }

    console.log('TicketCardssss=====>>>', data);
    console.log('TicketCardsss   s=====>>>', props);
    return (
        <>
            {props?.length > 0 ? props?.map((val, index) => {
                return (
                    <Card className={'mb-4'}>
                        <Card.Header className='p-0 m-0 border-0'>
                            <div className={'px-1 py-1 pb-0 d-flex align-items-center'}>

                                <div className={'d-flex align-items-center'}>
                                    <Link className={`iconlogo1`}>
                                        <img className="img-fluid h30" src={plane} alt="icon" />
                                        {/* <img className="img-fluid h40" src={val.airline_logo} alt="icon" /> */}
                                    </Link>
                                    {/* {val?.air_line_logos?.map((airline, i) => {
                                        return (<Link className={`iconlogo1 O${i + 1}`}><img className={'img-fluid'} src={airline.logo} alt={'icon'} /></Link>)
                                        })
                                        } */}
                                </div>
                                <h5 className={'ms-2 titlehead'}>{val.sector}</h5>
                            </div>
                            {/* <Table className={'mb-0 booking-table'} responsive={'sm'}>
                                <thead>
                                    <tr>
                                        <th>Airline</th>
                                        <th>Sector/Date</th>
                                        <th className='text-center'>Timing</th>
                                        <th className='text-end'>Baggage/Meal</th>
                                        <th className='text-end'>Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </Table> */}
                        </Card.Header>
                        <Card.Body className='px-0 py-1 pt-0'>
                            <Table className={'mb-0 allView colored booking-table'} responsive={'sm'}>
                                <thead>
                                    <tr>
                                        <th>Airline</th>
                                        <th>Sector/Date</th>
                                        <th className='text-center'>Timing</th>
                                        <th className='text-end'>Baggage/Meal</th>
                                        <th className='text-end'>Price</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                {val?.tickets?.map((pnr, i) => {
                                    return (
                                        <tbody>
                                            <tr className={`cursor-pointer ${(showSelectedSubparts && selectedSubpartIndex === i) && 'selected-tr'}`} onClick={() => ShowSubparts(i)}>
                                                <td><div className='airlinelogo'><img className={'img-fluid'} src={pnr?.air_line_logo} alt={'icon'} /></div></td>

                                                {/* <td className='first-td'><Link to={`/dashboard/pnr/${pnr?.pnr}`} className={'text-blue text-decoration-none fontsize14 fontweight600'}>{pnr?.pnr}</Link></td> */}
                                                <td>
                                                    <h5 className='text-uppercase fontweight700 fontsize16 mainblue'>{pnr?.sectorshort}</h5>
                                                    {pnr.subpart?.map((inval, i) => (
                                                        <p className='fontsize14 fontweight600 p-0 m-0 '>{inval?.day}, {formatDate(inval?.departure_date)}</p>
                                                    ))
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    <img alt='icon' className='' src={planeicon} />
                                                    {pnr.subpart?.map((inval, i) => (
                                                        <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_time} - {inval?.arivval_time}</p>
                                                    ))}
                                                </td>
                                                <td className='text-end'>
                                                    <h5 className='fontweight600 fontsize14 mainblue'>{`${pnr.meal == 1 ? 'Meal' : 'Meal Not'} Available`}</h5>
                                                    {pnr.ticket_type == 2 ?
                                                        (pnr.subpart?.map((inval, i) => (
                                                            (i == 0 || i == 1) && <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_baggage} + 7kg</p>
                                                        ))
                                                        )
                                                        :
                                                        (pnr.subpart?.map((inval, i) => (
                                                            i == 0 && <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_baggage} + 7kg</p>
                                                        ))
                                                        )
                                                    }
                                                </td>
                                                <td className='text-end'>
                                                    {isLogin ?
                                                        <h5 className='text-uppercase fontweight700 fontsize18 mainblue'>{pnr?.price > 0 ? `PKR - ${pnr?.price}` : 'Fare on call'}</h5>
                                                        :
                                                        <Button variant={''} className={'basic-btn mainbluebg'}
                                                            onClick={(e) => {
                                                                handleShow()
                                                            }}>Login For Price</Button>
                                                    }
                                                    {pnr.alert &&
                                                        <span className='fontsize12 fontweight500 mt-1 m-0 alert'>{pnr.alert}</span>
                                                    }
                                                </td>
                                                <td align={'end'}>
                                                    <Button variant={''} className={'bluebg px-2 py-1 btn text-white'}
                                                        onClick={(e) => {
                                                            setSelectedPnr({ date: pnr?.date, pnr: pnr?.pnr, price: pnr?.price });
                                                            handleShow()
                                                        }}>Book</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                })}
                            </Table>
                        </Card.Body>

                    </Card>
                )
            })
                :
                <h4 className='redtext m-0 p-0'>No Ticket Found</h4>
            }
            {(modalShow && isLogin) ? (
                <BookTicket show={modalShow} setShow={setModalShow} selectedPnr={selectedPnr} bookType={'user'} />
            ) : (
                <SigninPopup show={modalShow} setShow={setModalShow} onLoginSuccess={handleLoginSuccess} />
            )}


            {/* <Table responsive="md" className='booking-table AllView colored'>
                <thead>
                    <tr>
                        <th>Airline</th>
                        <th>Sector/Date</th>
                        <th className='text-center'>Timing</th>
                        <th className='text-end'>Baggage/Meal</th>
                        <th className='text-end'>Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody >

                    {data?.length > 0 ? data?.map((val, i) => {
                        return (
                            <>
                                <tr className={`bookingbox cursor-pointer ${(showSelectedSubparts && selectedSubpartIndex === i) && 'selected-tr'}`} onClick={() => ShowSubparts(i)}>
                                    <td className="">
                                        <img alt='icon' className='' title={val?.air_line} src={val?.air_line_logo} style={{ width: "80px", height: "40px", objectFit: 'contain' }} />
                                    </td>
                                    <td className='tsector'>
                                        <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.sector}</h5>
                                        {val.subpart?.map((inval, i) => (
                                            <p className='fontsize14 fontweight600 p-0 m-0 '>{inval?.day}, {formatDate(inval?.departure_date)}</p>
                                        ))
                                        }
                                    </td>

                                    <td className='text-center'>
                                        <img alt='icon' className='' src={planeicon} />
                                        {val.subpart?.map((inval, i) => (
                                            <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_time} - {inval?.arivval_time}</p>
                                        ))}
                                    </td>
                                    <td className='text-end'>
                                        <h5 className='fontweight600 fontsize14 mainblue'>{`${val.meal == 1 ? 'Meal' : 'Meal Not'} Available`}</h5>
                                        {val.ticket_type == 2 ?
                                            (val.subpart?.map((inval, i) => (
                                                (i == 0 || i == 1) && <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_baggage} + 7kg</p>
                                            ))
                                            )
                                            :
                                            (val.subpart?.map((inval, i) => (
                                                i == 0 && <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_baggage} + 7kg</p>
                                            ))
                                            )
                                        }
                                    </td>
                                    <td className='text-end'>
                                        {isLogin ?
                                            <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.price > 0 ? `PKR - ${val?.price}` : 'Fare on call'}</h5>
                                            :
                                            <Button variant={''} className={'basic-btn mainbluebg'} style={{ height: '40px' }}
                                                onClick={(e) => {
                                                    handleShow()
                                                }}>Login For Price</Button>
                                        }
                                        {val.alert &&
                                            <span className='fontsize12 fontweight500 mt-1 m-0 alert'>{val.alert}</span>
                                        }
                                    </td>
                                    <td align={'end'}>
                                        <Button variant={''} className={'basic-btn text-uppercase'} style={{ height: '40px' }}
                                            onClick={(e) => {
                                                setSelectedPnr({ date: val?.date, pnr: val?.pnr, price: val?.price });
                                                handleShow()
                                            }}>Book</Button>
                                    </td>
                                </tr>
                                {val?.subpart?.map((invall, ii) => (
                                    <tr className={`subpart-row-entering ${(showSelectedSubparts && selectedSubpartIndex === i) && 'subpart-row-entered'}`} id={i} style={{ background: 'black', color: 'white' }}>
                                        <td>
                                            <p className='fontsize14 fontweight600 p-0 m-0'>{val.air_line}</p>
                                        </td>
                                        <td>
                                            <p className='fontsize14 fontweight600 p-0 m-0'>{invall?.departure_sector_name} , {formatDate(invall?.departure_date)}</p>
                                        </td>
                                        <td className='text-center'>
                                            <p className='fontsize14 fontweight600 p-0 m-0'>{invall?.departure_time} - {invall?.arivval_time}</p>
                                        </td>
                                        <td className='text-end'>
                                            <p className='fontsize14 fontweight600 p-0 m-0'>{invall?.departure_baggage || 'N/A'}</p>
                                        </td>
                                        <td className='text-end'>
                                            <p className='fontsize14 fontweight600 p-0 m-0'>{val?.price}</p>
                                        </td>
                                        <td></td>
                                    </tr>
                                ))}
                            </>
                        )
                    })
                        :
                        <h4 className='redtext m-0 p-0'>No Ticket Found</h4>
                    }
                    {(modalShow && isLogin) ? (
                        <BookTicket show={modalShow} setShow={setModalShow} selectedPnr={selectedPnr} bookType={'user'} />
                    ) : (
                        <SigninPopup show={modalShow} setShow={setModalShow} onLoginSuccess={handleLoginSuccess} />
                    )}
                </tbody>
            </Table> */}
        </>

    )
}

export default TicketCard
