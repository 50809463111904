import React from 'react'
import Header from '../Header'
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap'

//import image
import bannerbgimg from '../../images/bannerbg.png'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { API_BASE_URL, getBearerToken } from '../../functions'
import axios from 'axios'
import { useState } from 'react'
import TicketCard from './componnents/TicketCard'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TopBoxes from '../pages/dashboard-compo/TopBoxes'
import MySectorBox from './componnents/MySectorBox'

const Home = () => {
  const [data, setData] = useState('')
  const [tickets, setTickets] = useState([])
  const Navigate = useNavigate()
  const [searchQuery, setSearchQuesry] = useState({
    dip: '',
    arriv: '',
    date: ''
  })


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    axios.get(`${API_BASE_URL}/available-sector`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data

      setData(response?.data?.data)
      setTickets(response?.data?.data?.tickets)
    }).catch(error => {
      // Handle errors
      console.error(error);
    });
  }, [])

  const handalSearch = (e) => {
    Navigate(`/search/${searchQuery.dip}/${searchQuery.arriv}/${searchQuery.date}`)
    // axios.post(`${API_BASE_URL}/search-flights`, {
    //   departure: searchQuery.dip,
    //   arrival: searchQuery.arriv,
    //   date: searchQuery.date
    // }
    // )
    //   .then(response => {
    //     // Handle the response data
    //     setTickets(response?.data?.data)
    //     console.log('response', response);
    //     // setLoading(false)
    //   }).catch(error => {
    //     // Handle errors
    //     console.error(error?.response?.data.message);
    //     console.log('error', error);

    //     // setLoading(false)
    //   });
  }

  const getRandomDarkColor = () => {
    // Set a darkness threshold (adjust as needed)
    const darknessThreshold = 128;

    // Generate random color components (0-255)
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Calculate the brightness of the color
    // You can use various algorithms to determine brightness, here's a simple one
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

    // Check if the color is dark enough
    if (brightness < darknessThreshold) {
      // Return the dark color in RGB format
      return `rgb(${red},${green},${blue})`;
    } else {
      // If the color is not dark enough, recursively generate another color
      return getRandomDarkColor();
    }
  };

  const getRandomColor = () => {
    const randomNum = Math.floor(Math.random() * 3); // Generates a random number between 0 and 2
    switch (randomNum) {
      case 0:
        return 'primarylighterbg';
      case 1:
        return 'infolighterbg';
      case 2:
        return 'warninglighterbg';
      default:
        return 'errorlighterbg';
    }
  };

  // console.log(data);



  return (
    <>
      <section className='mainbanner'>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto col-11'}>
          <div className='text-center bannerbox'>
            <div className="titlebox">
              <h1 className='mainhedding'>Welcome To <span className='fontweight700 mainorange fontSans'>Dhillu & Sahi</span> Travels Daska, Pakistan</h1>
              <p className='whitetext fontsize16 underline'>A Group Of Faizan Travels Daska</p>
            </div>
            <div className='whitebox'>
              <div className='text-start'>
                <h5 className='text-uppercase fontweight700 fontsize20'>Search Group Tickets</h5>
                <small className='mainorange'>Here you can easily search our available group tickets.</small>
              </div>
              <Form className={'AllForm mt-2 d-blok d-md-flex align-items-center w-100'} onSubmit={handalSearch}>
                <div className="inputsbox d-flex flex-column flex-md-row">
                  <Form.Group className="form-group w-100" controlId="exampleForm.ControlInput1" style={{ flex: '1 0 0' }}>
                    <Form.Label>Departure</Form.Label>
                    <Form.Control

                      required
                      type="text"
                      name={''}
                      id={''}
                      placeholder={'LHE'}
                      onChange={(e) => setSearchQuesry({ ...searchQuery, dip: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="form-group w-100" controlId="exampleForm.ControlInput1" style={{ flex: '1 0 0' }}>
                    <Form.Label>Arrival</Form.Label>
                    <Form.Control

                      required
                      type="text"
                      name={''}
                      id={''}
                      placeholder={'DXB'}
                      onChange={(e) => setSearchQuesry({ ...searchQuery, arriv: e.target.value })}

                    />
                  </Form.Group>
                  <Form.Group className="form-group w-100" controlId="exampleForm.ControlInput1" style={{ flex: '1 0 0' }}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control

                      required
                      type="date"
                      name={''}
                      id={''}
                      placeholder={'data'}
                      onChange={(e) => setSearchQuesry({ ...searchQuery, date: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <Button variant={''} type='submit' className={'basic-btn text-uppercase h-56'}>Search Flight</Button>
              </Form>
            </div>
          </div>
        </Col>
        <img alt='icon' className='bannerbg' src={bannerbgimg} />
      </section>
      <section className='ticketslist py-5'>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto col-11'}>
          <h5 className='text-uppercase fontweight700 fontsize20'>Available Sectors</h5>
          <Row className={'mt-4 topBoxes'}>
            {data?.mysectors?.length > 0 && data?.mysectors?.map((val, i) => {
              return (
                <MySectorBox
                  key={i}
                  boxcolor={getRandomColor()}
                  colordiffrent={i == 0 ? 'solidcolor1text' : i == 1 ? 'solidcolor2text' : i == 2 ? 'solidcolor3text' : 'solidcolor4text'}
                  colormini={i == 0 ? 'solidcolor1text' : i == 1 ? 'solidcolor2text' : i == 2 ? 'solidcolor3text' : 'solidcolor4text'}
                  bgcolor={i == 0 ? 'gradiant1bg' : i == 1 ? 'gradiant1bg' : i == 2 ? 'gradiant2bg' : 'gradiant3bg'}
                  title={val?.name}
                  minitext={val?.totalpnr}
                  photoicon={val?.image}
                  id={val?.id}
                />
              )
            })}
          </Row>
          {/* {data?.sector?.length > 0 ? <div className="sectors mt-3 overflow-auto">
            <div className="d-flex mt-4">
              {data?.sector?.length > 0 && data?.sector.map((val) => {
                return (<Link className='cextorbox' style={{ color: `${getRandomDarkColor()}` }} to={`/search/${val?.from}/${val.to}/${val?.date}`} >{val?.from}-{val?.to}</Link>)
              })}
            </div>
          </div>
            :
            <div className={'mt-3 w-100 text-center'}>
              <Skeleton count={1} width={'100%'} height={35} style={{ marginBottom: '15px' }} />
            </div>
          }
          {tickets?.length > 0 ?
            <>
              <Table responsive="md" className='booking-table'>
                <thead>
                  <tr>
                    <th>Airline</th>
                    <th>Sector/Date</th>
                    <th className='text-center'>Timing</th>
                    <th className='text-end'>Baggage/Meal</th>
                    <th className='text-end'>Price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody >
                  <TicketCard props={tickets} />
                </tbody>
              </Table>
            </>
            :
            <Skeleton count={5} width={'100%'} height={108} style={{ marginBottom: '15px' }} />
          } */}
        </Col>
      </section>
    </>
  )
}

export default Home
