import React from 'react'
import { Col, NavItem, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import footer_logo from '../../../images/footer_logo_white.svg';

const Footer = () => {
  return (
    <>
      <section className='publicfooter'>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto col-11'}>
          <Row>
            <Col xxl={'6'} xl={'6'} lg={'6'}>
              <Link to={`/`}><img className={'img-fluid fluid'} src={footer_logo} alt={'icon'} /></Link>
              <p className='m-0 p-0 mt-3 whitetext'>Fickle Flight is your one-stop travel portal. We offer hassle free flight and hotel bookings. We also have all your flight needs in you online shop.</p>
            </Col>
            <Col xxl={'6'} xl={'6'} lg={'6'}>
              <Row>
                <Col xxl={'4'} xl={'4'} lg={'4'} md={'6'} sm={'12'}>
                  <div>
                    <h5 className='fontsize20 fontweight500 whitetext mb-3'>Company</h5>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/`}>Home</Link></NavItem>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/aboutus`}>About Us</Link></NavItem>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/updates`}>Updates</Link></NavItem>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/contactus`}>Contact Us</Link></NavItem>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/bankaccounts`}>Bank Details</Link></NavItem>
                  </div>
                </Col>
                <Col xxl={'4'} xl={'4'} lg={'4'} md={'6'} sm={'12'}>
                  <div>
                    <h5 className='fontsize20 fontweight500 whitetext mb-3'>Products</h5>
                    {/* <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/wba`}>Wba</Link></NavItem> */}
                  </div>
                </Col>
                <Col xxl={'4'} xl={'4'} lg={'4'} md={'6'} sm={'12'}>
                  <div>
                    <h5 className='fontsize20 fontweight500 whitetext mb-3'>Portal</h5>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/signin`}>Login</Link></NavItem>
                    <NavItem><Link className={'nav-link fontsize16  whitetext mb-1'} to={`/signup`}>Register</Link></NavItem>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </section>
    </>
  )
}

export default Footer
